/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { colors, medPadding, stdPadding, subtextFontSize } from "../styles/common";
const offerStyle = `
	${subtextFontSize}
	position: absolute;
	top: calc(${stdPadding} / 2);
	left: calc(${stdPadding} / 2);
	color: ${colors["black"]};
	font-weight: bold;
	text-transform: uppercase;
	background-color: #FBED21;
	border-radius: 2px;
	padding: calc(${medPadding} /2) calc(${stdPadding} /2);
`;
const YellowOfferStrip = (props) => {
    let { offerString } = props;
    //not make strip visible if 0% OFF
    if (offerString === "0% OFF") {
        offerString = "";
    }
    if (offerString === undefined || offerString == "") {
        return null;
    }
    else {
        return <span css={css(offerStyle)} className="card-offer">{offerString}</span>;
    }
};
export default YellowOfferStrip;
